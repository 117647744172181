@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.edit-recurring-other {
  &__form {
    display: flex;
    flex-direction: column;
    background-color: light.$background-secondary-default;
    border-radius: corner-radius.$s;
    margin-bottom: spacing.$m;
    gap: spacing.$m;
    padding: spacing.$m;

    @media (min-width: 600px) {
      margin-bottom: spacing.$l;
    }
  }

  &__time {
    display: flex;
    align-items: flex-start;
    gap: spacing.$m;
  }

  &__input {
    width: 100%;

    @media (min-width: 600px) {
      max-width: 280px;
    }
  }

  &__time-to {
    display: flex;
    flex-grow: 1;
  }

  &__hr {
    align-self: center;
    width: 13px; //NOTE: This is a hr line with a magic width
    margin-top: 28px; // NOTE: Hard coded to make alignment pretty
  }
}
